import { Injectable } from '@angular/core';
import { DraftStorageEngine } from '../../db/DraftStorageEngine';
import { BehaviorSubject, Observable } from 'rxjs';
import { DraftUtils } from '../../../presentation/views/main-draft-view/components/draft-editor/draft-utils.class';
import { IDtoTagInfo } from '../../../api/dtos/uok';
import { DraftState } from '../draft';
import { Store } from '@ngxs/store';
import { IDtoDraft } from '../../../api/dtos/uok';
import { createDraftDtoInterface } from '../../../api/dtos/uok';

@Injectable()
export class DraftStateIndexeddb {
  private draftForm: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private isFormDirty: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private draftDto: BehaviorSubject<IDtoDraft | null> = new BehaviorSubject<IDtoDraft | null>(null);
  private static draftKey = 'draft';
  private static draftSettingIsDirtyKey = 'draftIsDirty';


  constructor(private storageEngine: DraftStorageEngine, private store: Store) {
    this.storageEngine.getSetting(DraftStateIndexeddb.draftSettingIsDirtyKey).then(stored => {
      if (stored) {
        this.isFormDirty.next(stored.data);
      } else {
        this.isFormDirty.next(false);
      }
    });

    this.storageEngine.getItem(DraftStateIndexeddb.draftKey).then((stored) => {
      if (stored) {
        this.draftForm.next(stored.model ? JSON.parse(stored.model) : null);
        this.draftDto.next(stored.dto ? JSON.parse(stored.dto) : null);
      }
    });
  }

  public async Init() {
    const stored = await this.storageEngine.getItem(DraftStateIndexeddb.draftKey);
    if (stored) {
      this.draftForm.next(stored.model ? JSON.parse(stored.model) : null);
      this.draftDto.next(stored.dto ? JSON.parse(stored.dto) : null);
    }

    const storedIsDirty = await this.storageEngine.getSetting(DraftStateIndexeddb.draftSettingIsDirtyKey);
    if (storedIsDirty) {
      this.isFormDirty.next(storedIsDirty.data);
    } else {
      this.isFormDirty.next(false);
    }
  }

  public async SaveDataFromForm(formData: any) {
    const stored = await this.storageEngine.getItem(DraftStateIndexeddb.draftKey);
    let dto = createDraftDtoInterface();

    if (stored && stored.dto) {
      dto = JSON.parse(stored.dto);
    }

    this.updateDraftDtoFromForm(dto, formData);
    await this.storageEngine.setItem(DraftStateIndexeddb.draftKey, formData, dto);

    this.isFormDirty.next(true);
    await this.storageEngine.setSetting(DraftStateIndexeddb.draftSettingIsDirtyKey, true);
  }

  public async SetSavedFormFlag() {
    this.isFormDirty.next(false);
    await this.storageEngine.setSetting(DraftStateIndexeddb.draftSettingIsDirtyKey, false);
  }

  public IsDirty() {
    return this.isFormDirty.asObservable();
  }

  public async GetIsDirtyFromDb(): Promise<boolean> {
    return (await this.storageEngine.getSetting(DraftStateIndexeddb.draftSettingIsDirtyKey))?.data ?? false;
  }

  public async SaveDataFromDto(dto: IDtoDraft) {
    this.draftDto.next(dto);

    this.isFormDirty.next(false);
    await this.storageEngine.setSetting(DraftStateIndexeddb.draftSettingIsDirtyKey, false);

    const mapper = new DraftUtils(this.store);
    const mapped = mapper.draftDtoToFormData(dto);
    await this.storageEngine.setItem(DraftStateIndexeddb.draftKey, mapped, dto);
    this.draftForm.next(mapped);
  }

  public GetDraftDto(): Observable<IDtoDraft | null> {
    return this.draftDto.asObservable();
  }

  public GetDraftForm(): Observable<any> {
    return this.draftForm.asObservable();
  }

  async ClearDraft() {
    await this.storageEngine.clearItem(DraftStateIndexeddb.draftKey);
    await this.storageEngine.setSetting(DraftStateIndexeddb.draftSettingIsDirtyKey, false);
    this.draftDto.next(null);
    this.draftForm.next(null);
    this.isFormDirty.next(false);
  }

  private updateDraftDtoFromForm(draftDto: IDtoDraft, currentDraftForm: any) {
    draftDto.DbId = currentDraftForm.dbId;
    draftDto.DraftId = currentDraftForm.draftId;
    draftDto.SystemName = currentDraftForm.selectedCategory;
    draftDto.IsModifiable = (currentDraftForm.ctIsModifiable);
    draftDto.Name.Value = currentDraftForm.ctName as string;
    draftDto.Abstract.Value = currentDraftForm.ctAbstract as string;

    if (draftDto.Tags) {
      draftDto.Tags = this.tagsToDto(currentDraftForm.tags);
    }

    if (draftDto.Description) {
      draftDto.Description.JsonValue = currentDraftForm.ctDescription;
    }

    if (draftDto.NameChinese) {
      draftDto.NameChinese.Value = currentDraftForm.ctNameChinese;
    }

    if (draftDto.NameChineseDescription) {
      draftDto.NameChineseDescription.JsonValue = currentDraftForm.ctNameChineseDescription;
    }

    if (draftDto.Location) {
      draftDto.Location.JsonValue = currentDraftForm.ctLocation;
    }

    if (draftDto.HowToFind) {
      draftDto.HowToFind.JsonValue = currentDraftForm.ctHowToFind;
    }

    if (draftDto.Actions) {
      draftDto.Actions.JsonValue = currentDraftForm.ctActions;
    }

    if (draftDto.Features) {
      draftDto.Features.JsonValue = currentDraftForm.ctFeatures;
    }

    if (draftDto.Kind) {
      draftDto.Kind.Value = (currentDraftForm.ctKind) ?? -1;
    }

    if (draftDto.Kind2nd) {
      draftDto.Kind2nd.Value = (currentDraftForm.ctKind2nd) ?? -1;
    }

    if (draftDto.Character) {
      draftDto.Character.Value = (currentDraftForm.ctCharacter) ?? -1;
    }

    if (draftDto.Element) {
      draftDto.Element.Value = (currentDraftForm.ctElement) ?? -1;
    }

    if (draftDto.EnergyLevel) {
      draftDto.EnergyLevel.Value = (currentDraftForm.ctEnergyLevel) ?? -1;
    }

    if (draftDto.Limb) {
      draftDto.Limb.Value = (currentDraftForm.ctLimb) ?? -1;
    }

    if (draftDto.ActivityTimeMax) {
      draftDto.ActivityTimeMax.Value = (currentDraftForm.ctActivityTimeMax) ?? -1;
    }

    if (draftDto.ActivityTimeMin) {
      draftDto.ActivityTimeMin.Value = (currentDraftForm.ctActivityTimeMin) ?? -1;
    }

    if (draftDto.Pathway) {
      draftDto.Pathway.JsonValue = currentDraftForm.ctPathway;
    }

    if (draftDto.IsDeep) {
      draftDto.IsDeep.Value = (currentDraftForm.ctIsDeep) == 0;
    }

    if (draftDto.NameLatin) {
      draftDto.NameLatin.Value = currentDraftForm.ctNameLatin;
    }

    if (draftDto.Origin) {
      draftDto.Origin.JsonValue = currentDraftForm.ctOrigin;
    }

    if (draftDto.Insertion) {
      draftDto.Insertion.JsonValue = currentDraftForm.ctInsertion;
    }

    if (draftDto.Nerve) {
      draftDto.Nerve.JsonValue = currentDraftForm.ctNerve;
    }

    if (draftDto.Notes) {
      draftDto.Notes.JsonValue = currentDraftForm.ctNotes;
    }

    if (draftDto.AssociatedOrgans) {
      draftDto.AssociatedOrgans.Value = currentDraftForm.ctAssociatedOrgans;
    }

    if (draftDto.ClientPosition) {
      draftDto.ClientPosition.Value = DraftUtils.getClientPositionsForDto(currentDraftForm.ctClientPosition);
    }

    if (draftDto.ClientPositionMonitoring) {
      draftDto.ClientPositionMonitoring.JsonValue = currentDraftForm.ctClientPositionMonitoring;
    }

    if (draftDto.HandMonitoring) {
      draftDto.HandMonitoring.JsonValue = currentDraftForm.ctHandMonitoring;
    }

    if (draftDto.HandStabilizing) {
      draftDto.HandStabilizing.JsonValue = currentDraftForm.ctHandStabilizing;
    }

    if (draftDto.MonitoringDirection) {
      draftDto.MonitoringDirection.JsonValue = currentDraftForm.ctMonitoringDirection;
    }

    if (draftDto.Email) {
      draftDto.Email.Value = currentDraftForm.ctEmail;
    }

    if (draftDto.Website) {
      draftDto.Website.Value = currentDraftForm.ctWebsite;
    }

    if (draftDto.Facebook) {
      draftDto.Facebook.Value = currentDraftForm.ctFacebook;
    }

    if (draftDto.Image01) {
      draftDto.Image01 = currentDraftForm.image1;
    }

    if (draftDto.Image02) {
      draftDto.Image02 = currentDraftForm.image2;
    }

    if (draftDto.Image03) {
      draftDto.Image03 = currentDraftForm.image3;
    }

    if (draftDto.Image04) {
      draftDto.Image04 = currentDraftForm.image4;
    }

    if (draftDto.Image05) {
      draftDto.Image05 = currentDraftForm.image5;
    }

    if (draftDto.Image06) {
      draftDto.Image06 = currentDraftForm.image6;
    }

    if (draftDto.Document01) {
      draftDto.Document01 = currentDraftForm.document1;
    }

    if (draftDto.Document02) {
      draftDto.Document02 = currentDraftForm.document2;
    }

    if (draftDto.Document03) {
      draftDto.Document03 = currentDraftForm.document3;
    }

    if (draftDto.Document04) {
      draftDto.Document04 = currentDraftForm.document4;
    }

    if (draftDto.Document05) {
      draftDto.Document05 = currentDraftForm.document5;
    }

    if (draftDto.Document06) {
      draftDto.Document06 = currentDraftForm.document6;
    }

    if (draftDto.Video01) {
      draftDto.Video01 = currentDraftForm.video1;
    }

    if (draftDto.Video02) {
      draftDto.Video02 = currentDraftForm.video2;
    }

    if (draftDto.Video03) {
      draftDto.Video03 = currentDraftForm.video3;
    }

    if (draftDto.Video04) {
      draftDto.Video04 = currentDraftForm.video4;
    }

    if (draftDto.Video05) {
      draftDto.Video05 = currentDraftForm.video5;
    }

    if (draftDto.Video06) {
      draftDto.Video06 = currentDraftForm.video6;
    }
  }

  private tagsToDto(selectedTags: string[]): IDtoTagInfo[] {
    const tmp: IDtoTagInfo[] = [];
    const dtoTags = this.store.selectSnapshot(DraftState.tagsInfo);

    selectedTags?.forEach(t => {
      const dto = dtoTags.find(x => x.Name === t);
      if (dto) {
        tmp.push(dto);
      }
    });

    return tmp;
  }
}
