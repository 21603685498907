import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { AccountSignOutActions, AccountState } from './core/state/account';
import { UpdateActiveMenu } from './core/state/main-menu/main-menu-actions.class';
import { ToastController } from './presentation/ui-kit/controllers/toast-controller.class';
import { UseCasesNotificationHandler } from './presentation/ui-kit/shared/use-cases-notification-handler.class';
import { UserUseCaseFactory } from './use-cases/user';
import { UseCaseActionName } from './core/utils';
import { UserState } from './core/state/user';
import { StripeUseCaseFactory } from './use-cases/stripe';
import { TranslateConfig } from './core/services/translate-config.class';
import { UtilityUseCaseFactory } from './use-cases/utility/utility-use-case-factory';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Knowlative Web App';
  private notificationHandler: UseCasesNotificationHandler;

  constructor(
    private iconReg: SvgIconRegistryService,
    private actions: Actions,
    private router: Router,
    private store: Store,
    private translateConfig: TranslateConfig,
    translate: TranslateService,
    toastController: ToastController,
  ) {
    this.registerIcons();
    this.notificationHandler = new UseCasesNotificationHandler(store, translate, toastController);
  }

  private getUserUc = UserUseCaseFactory.GetUserInfo();
  private getStripeUc = StripeUseCaseFactory.LoadStripeInfo();
  private getCultureDefinitionsUc = UtilityUseCaseFactory.GetCultureDefinitions();

  ngOnDestroy(): void {
    this.notificationHandler.dispose();
    this.getUserUc.dispose();
    this.getStripeUc.dispose();
    this.getCultureDefinitionsUc.dispose();
  }

  ngOnInit() {
    // this is used to force the app to load the browser culture if not yet loaded from the back-end
    this.translateConfig.getCurrentLang();

    this.actions.pipe(ofActionDispatched(AccountSignOutActions.Succeded)).subscribe(() => {
      this.router.navigate(['/signin']).then();
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.store.dispatch(new UpdateActiveMenu());
      }
    });

    // this.store.select(UokDetailState.header).subscribe(h => {
    //     if (!h) {
    //         return;
    //     }
    //
    //     this.store.dispatch(new SetLastMenuItem({
    //         label: h.nameFull,
    //         path: `/main/uok-detail/${h.uokId}`,
    //         icon: h.itemIcon as AppIconNames
    //     }))
    // })

    this.store.select(UserState.userNeedPaymentUpdate)
      .subscribe(paymentUpdateNeeded => {
        if (paymentUpdateNeeded) {
          // this.router.navigate([AppRoutes.updatePayment]).then();
        }
      });

    // refresh user on app reload
    if (this.store.selectSnapshot(AccountState.isAuthenticated)) {
      this.getUserUc.init();
      this.getUserUc.execute(UseCaseActionName.run);
      this.getStripeUc.init();
      this.getStripeUc.execute(UseCaseActionName.run);
      this.getCultureDefinitionsUc.init();
      this.getCultureDefinitionsUc.execute(UseCaseActionName.run);
    }
  }

  private registerIcons() {
    this.iconReg.loadSvg('assets/icons/knowlative.svg', 'kicon-knowlative')?.subscribe();
    this.iconReg.loadSvg('assets/icons/k-logo-gliph-only.svg', 'kicon-logo-gliph-only')?.subscribe();

    // Knowlative icons
    this.iconReg.loadSvg('assets/icons/uoks/acupoint.svg', 'kicon-acupoint')?.subscribe();
    this.iconReg.loadSvg('assets/icons/uoks/anatomy.svg', 'kicon-anatomy')?.subscribe();
    this.iconReg.loadSvg('assets/icons/uoks/author.svg', 'kicon-author')?.subscribe();
    this.iconReg.loadSvg('assets/icons/uoks/bone.svg', 'kicon-bone')?.subscribe();
    this.iconReg.loadSvg('assets/icons/uoks/digital-mode.svg', 'kicon-digital-mode')?.subscribe();
    this.iconReg.loadSvg('assets/icons/uoks/format.svg', 'kicon-format')?.subscribe();
    this.iconReg.loadSvg('assets/icons/uoks/frequency.svg', 'kicon-frequency')?.subscribe();
    this.iconReg.loadSvg('assets/icons/uoks/group.svg', 'kicon-group')?.subscribe();
    this.iconReg.loadSvg('assets/icons/uoks/joint.svg', 'kicon-joint')?.subscribe();
    this.iconReg.loadSvg('assets/icons/uoks/meridian.svg', 'kicon-meridian')?.subscribe();
    this.iconReg.loadSvg('assets/icons/uoks/muscle.svg', 'kicon-muscle')?.subscribe();
    this.iconReg.loadSvg('assets/icons/uoks/NL.svg', 'kicon-NL')?.subscribe();
    this.iconReg.loadSvg('assets/icons/uoks/NV.svg', 'kicon-NV')?.subscribe();
    this.iconReg.loadSvg('assets/icons/uoks/reference.svg', 'kicon-reference')?.subscribe();
    this.iconReg.loadSvg('assets/icons/uoks/technique.svg', 'kicon-technique')?.subscribe();
    this.iconReg.loadSvg('assets/icons/uoks/test.svg', 'kicon-test')?.subscribe();
    this.iconReg.loadSvg('assets/icons/uoks/vocabulary.svg', 'kicon-vocabulary')?.subscribe();
    this.iconReg.loadSvg('assets/icons/uoks/pro.svg', 'kicon-pro')?.subscribe();


    // outlined
    this.iconReg.loadSvg('assets/icons/outline/lifebuoy.svg', 'undefined')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/user.svg', 'user')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/exclamation-circle.svg', 'info')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/exclamation-triangle.svg', 'warning')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/x-circle.svg', 'error')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/x.svg', 'close')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/home.svg', 'home')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/book-open.svg', 'book-open')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/bookmark-outline.svg', 'bookmark-outline')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/briefcase.svg', 'briefcase')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/shopping-cart.svg', 'shopping-cart')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/pencil.svg', 'pencil')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/x.svg', 'x')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/document-duplicate.svg', 'document-duplicate')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/star.svg', 'star')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/document.svg', 'document')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/lock-open.svg', 'lock-open')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/lock-closed.svg', 'lock-closed')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/list-bullet.svg', 'list-bullet')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/text-outline.svg', 'text-outline')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/browsers-outline.svg', 'browsers-outline')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/paper-airplane.svg', 'paper-airplane')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/magnifying-glass.svg', 'magnifying-glass')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/arrow-down-tray.svg', 'arrow-down-tray')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/chevron-left.svg', 'chevron-left')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/chevron-right.svg', 'chevron-right')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/sign-out.svg', 'sign-out')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/eye.svg', 'eye')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/squares.svg', 'squares')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/menu.svg', 'menu')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/check.svg', 'check')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/building-library.svg', 'building-library')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/question-mark-circle.svg', 'question-mark-circle')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/video-camera.svg', 'video-camera')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/arrow-up-tray.svg', 'arrow-up-tray')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/trash.svg', 'trash')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/plus.svg', 'plus')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/link.svg', 'link')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/pencil-square.svg', 'pencil-square')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/wrench-screwdriver.svg', 'wrench-screwdriver')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/check-circle.svg', 'check-circle')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/circle.svg', 'circle')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/cloud-arrow-down.svg', 'cloud-arrow-down')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/language.svg', 'language')?.subscribe();
    this.iconReg.loadSvg('assets/icons/outline/at-symbol.svg', 'at-symbol')?.subscribe();

    // filled
    this.iconReg.loadSvg('assets/icons/solid/star.svg', 'star-solid')?.subscribe();
    this.iconReg.loadSvg('assets/icons/solid/bookmark-solid.svg', 'bookmark-solid')?.subscribe();
    this.iconReg.loadSvg('assets/icons/solid/check-solid.svg', 'check-solid')?.subscribe();
    this.iconReg.loadSvg('assets/icons/solid/x-circle-solid.svg', 'x-circle-solid')?.subscribe();

    // flags
    this.iconReg.loadSvg('assets/icons/flags/it.svg', 'f-it')?.subscribe();
    this.iconReg.loadSvg('assets/icons/flags/us.svg', 'f-us')?.subscribe();
    this.iconReg.loadSvg('assets/icons/flags/es.svg', 'f-es')?.subscribe();
  }
}
