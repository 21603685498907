import { AppIconNames } from '../../../app-icon-names';
import { SearchEngineItemModelProduct } from '../shared';

export interface SearchEngineItemModel {
  systemName: string;
  operatorId: number;
  contentId: number;
  contentRouterLink: string;
  cultureName: string;
  contentSystemName: string;
  name: string;
  nameLocalized: string;
  abstract: string;
  contentLastAction: string;
  contentLastActionTimestamp: Date;
  contentImageUrl: string;
  contentUpdateForLatest: Date;
  uokPublishedNumber: number;
  // productIds: number[];
  licence: string;


  isAccessible: boolean;
  contentIcon: AppIconNames;
  needsLicenceUpdate: boolean;
  products: SearchEngineItemModelProduct[];
}
