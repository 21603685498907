import { environment } from '../../../environments/environment';

export abstract class TranslateConfig {

  public static readonly defaultLanguage = environment.defaultCulture;
  protected currentLang: string | null = null;

  constructor() {
    // this.currentLang = 'en';
  }

  // public abstract getDefaultLanguage(): string;

  public abstract setLanguage(setLang: string): void;

  public abstract getCurrentLang(): string;

  public abstract translate(key: string): string;
}
